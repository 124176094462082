import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
function AppFooter() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const footerNavigation = {
    solutions: [
      { name: "Marketing", linkTo: "account" },
      { name: "Analytics", linkTo: "account" },
      { name: "Commerce", linkTo: "account" },
      { name: "Insights", linkTo: "account" },
    ],
    support: [
      { name: "Pricing", linkTo: "account" },
      { name: "Documentation", linkTo: "account" },
      { name: "Guides", linkTo: "account" },
      { name: "API Status", linkTo: "account" },
    ],
    company: [
      // { name: "About", linkTo: "/about" },
      // { name: "Blog", linkTo: "account" },
      // { name: "Jobs", linkTo: "account" },
      // { name: "Press", linkTo: "account" },
      // { name: "Partners", linkTo: "account" },
    ],
    legal: [
      // { name: "Claim", linkTo: "terms" },
      { name: "Privacy", linkTo: "terms" },
      { name: "Terms", linkTo: "terms" },
    ],
    social: [
      {
        name: "Facebook",
        socialURL: "https://www.facebook.com/careplicity",
        icon: (props) => (
          <FontAwesomeIcon icon={["fab", "facebook"]} {...props} />
        ),
      },
      {
        name: "LinkedIn",
        socialURL: "https://www.linkedin.com/company/careplicity",
        icon: (props) => (
          <FontAwesomeIcon icon={["fab", "linkedin"]} {...props} />
        ),
      },
    ],
  };
  return (
    <footer className="bg-gray-50 py-10 mt-5" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto pt-5 pb-8 px-4 sm:px-6 lg:pt-1 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            {/* <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.linkTo}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Support
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {footerNavigation.support.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.linkTo}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.linkTo}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.linkTo}
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-12 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            <p className="mt-4 text-base text-gray-500">
              The latest news, articles, and resources, sent to your inbox.
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:placeholder-gray-400"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full flex items-center justify-center bg-gradient-to-r from-secondary-600 to-primary-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-secondary-700 hover:to-primary-700"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="md:flex md:items-center md:justify-between lg:mt-16">
          <div className="flex space-x-6 md:order-2">
            {footerNavigation.social.map((item) => (
              <a
                key={item.name}
                href={item.socialURL}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2022 Careplicity. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default AppFooter;
