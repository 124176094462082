import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function AppLoader({ msg, size = "1x" }) {
  return (
    <div className="flex justify-center items-center space-x-2">
      <FontAwesomeIcon
        icon={["fad", "spinner-third"]}
        className={`mr-2 animate-spin`}
        spin
        size={size}
        color="#0c1873"
      />
      {msg}
    </div>
  );
}

export default AppLoader;
