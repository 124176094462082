import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AppLoader from "../components/Fluff/AppLoader";
//Import Component
import { routes } from "./AppRoutes";

export default function RoutePaths() {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        {routes.map((route, routeIndex) => {
          return (
            <Route key={routeIndex} path={route.path} element={route.element}>
              {route.children.map((subRoute, index) => {
                return (
                  <Route
                    key={index}
                    path={subRoute.path}
                    element={subRoute.element}
                    index={subRoute.index}
                  />
                );
              })}
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
}
