import React from "react";

import {
  AppFormField,
  FormContainer,
  FormResponse,
  SubmitButton,
} from "../components/Forms";

function FormPage({
  btnTitle = "Submit",
  formTitle,
  formItems,
  callSubmissionFunction,
  formResponse = {
    status: null,
  },
}) {
  return (
    <div>
      <h2>{formTitle}</h2>
      <FormContainer
        initialValues={{
          ...formItems.initValues,
        }}
        onSubmit={(values) => {
          callSubmissionFunction(values);
        }}
        validationSchema={formItems.validation}
      >
        <div>
          {formItems.formItems.map((form) => (
            <AppFormField key={form.id} {...form} />
          ))}

          {formResponse.status && (
            <div className={`mt-2 mb-2  ${formResponse.className}`}>
              <FormResponse
                loading={formResponse.loading}
                showAlert={formResponse.showAlert}
                alertColor={formResponse.color}
                alertMsg={formResponse.msg}
              />
            </div>
          )}
          <SubmitButton title={btnTitle} />
        </div>
      </FormContainer>
    </div>
  );
}

export default FormPage;
