import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";

//Import Component
import RoutePaths from "./routes/RoutePaths";

//Import Style sheets
import "./App.css";

// fontawesome
import "./assets/LoadedIcons";
import Outage from "./pages/Outage";

Amplify.configure(awsExports);

export default function App() {
  return (
    <Router>
      <RoutePaths />
    </Router>
  );
  // return <Outage />;
}
