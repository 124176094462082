/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import logo from "../assets/images/careplicity_right.svg";
import { Link } from "react-router-dom";
import { solutions } from "../assets/copy/Industries";
export default function AppNavbar() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <header>
      <Popover className="relative bg-white">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <img className="h-12 w-auto sm:h-16" src={logo} alt="" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
              <span className="sr-only">Open menu</span>
              <FontAwesomeIcon
                icon={["fal", "bars"]}
                className="h-6 w-6"
                aria-hidden="true"
              />
            </Popover.Button>
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="https://savvycal.com/amplefi/schedule-a-chat"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-secondary-600 to-primary-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-secondary-700 hover:to-primary-700"
            >
              Schedule a Chat
            </a>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div></div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                      <span className="sr-only">Close menu</span>
                      <FontAwesomeIcon
                        icon={["fal", "x"]}
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="mt-6">
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Ready to Start?
                  </p>
                  <a
                    href="https://savvycal.com/amplefi/schedule-a-chat"
                    className="w-full flex items-center justify-center bg-gradient-to-r from-ampPink-600 to-ampOrange-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-secondary-700 hover:to-primary-700 my-3"
                  >
                    Schedule a Chat
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
}
