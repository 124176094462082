import React from "react";
import { Link } from "react-router-dom";
function NavigationBtn({ title, color, link, navigateTo }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const buttonColors = {
    secondary:
      "text-white bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500",
    secondaryLight:
      "text-secondary-700 bg-secondary-100 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500",
    error:
      "text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
    errorLight:
      "text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
    link: "text-secondary-600  underline  hover:underline-offset-4",
  };
  return (
    <a
      as={link ? Link : null}
      href={navigateTo}
      className={classNames(
        buttonColors[color],
        "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-xl"
      )}
    >
      {title}
    </a>
  );
}

export default NavigationBtn;
