import React, { lazy } from "react";
import PageLayout from "./PageLayout";

//Import Component
const Home = lazy(() => import("../pages/Home"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Successful = lazy(() => import("../pages/Successful"));
const Cancelled = lazy(() => import("../pages/Cancelled"));

const Hospitality = lazy(() => import("../pages/Hospitality"));
const Terms = lazy(() => import("../pages/Terms"));

export let routes = [
  {
    element: <PageLayout />,
    path: "/",
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "hospitality",
        element: <Hospitality />,
      },

      {
        path: "success",
        element: <Successful />,
      },
      {
        path: "canceled",
        element: <Cancelled />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];
