import React from "react";
import { AppFooter } from "../constants";
import AppNavBar from "../constants/AppNavbar";
import Support from "../constants/Support";
import ErrorHanlder from "./ErrorHanlder";
function PageLayout() {
  return (
    <section>
      <AppNavBar />
      <Support />
      <div>
        <ErrorHanlder></ErrorHanlder>
      </div>
      <AppFooter />
    </section>
  );
}

export default PageLayout;
