import React from "react";
import Zendesk from "react-zendesk";
const { REACT_APP_ZENDESK } = process.env;

export default function Support() {
  const setting = {
    color: {
      theme: "#0f1d8a",
    },
    contactForm: {
      fields: [
        {
          id: "description",
          prefill: { "*": "Describe the issue you are facing..." },
        },
      ],
    },
  };
  return <Zendesk zendeskKey={REACT_APP_ZENDESK} {...setting} />;
}
