import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import {
  faTriangleExclamation,
  faFaceHeadBandage,
  faSpinnerThird,
} from "@fortawesome/pro-duotone-svg-icons";

// configure font awesome library
library.add(fab, fal, faTriangleExclamation, faFaceHeadBandage, faSpinnerThird);
