import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";

function ErrorHanlder() {
  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div>
        <div
          className="flex flex-col justify-center items-center"
          style={{ height: "80vh" }}
        >
          <p>Something went wrong. Refresh your browser, and try again.:</p>
          <div style={{ maxWidth: 550 }}>
            <p
              style={{
                overflow: "wrap",
                fontFamily: "monospace",
              }}
              className="text-red-500"
            >
              Error: {error.message}
            </p>
          </div>
          <p>
            If problem persists, please send us a ticket at{" "}
            <a
              href="mailto:support@amplefi.io"
              className="underline text-secondary-700 hover:underline-offset-4 "
            >
              support@amplefi.io
            </a>
          </p>
        </div>
      </div>
    );
  }

  return (
    <section>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Outlet />
      </ErrorBoundary>
    </section>
  );
}

export default ErrorHanlder;
