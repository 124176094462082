import React from "react";
function Hero({
  bgImg,
  addBgGray = false,
  title,
  subtitle,
  imgPosition = "object-center",
}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="relative">
      <div
        className={classNames(
          addBgGray && "bg-gray-100",
          "absolute inset-x-0 bottom-0 h-1/2"
        )}
      />
      <div>
        <div
          className="relative shadow-xl sm:overflow-hidden"
          style={{ height: "75vh" }}
        >
          <div className="absolute inset-0">
            <img
              className={classNames(imgPosition, "h-full w-full object-cover")}
              src={bgImg}
              alt="People working on laptops"
            />
            <div className="absolute inset-0 bg-secondary-500 mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-52 sm:px-6 sm:py-64 lg:py-56 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-4xl lg:text-6xl">
              {title}
            </h1>
            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-primary-100 sm:max-w-3xl">
              {subtitle}
            </p>
            <div className="mt-10 max-w-sm mx-auto sm:max-w-none flex justify-center items-center">
              <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                <a
                  href="https://savvycal.com/amplefi/schedule-a-chat"
                  className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-primary-700 bg-white hover:bg-primary-50 sm:px-2 lg:px-8"
                >
                  Schedule a Chat
                </a>
                <a
                  href="#"
                  className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-ampOrange-500 bg-opacity-60 hover:bg-opacity-70 sm:px-1 lg:px-8"
                >
                  Donate
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
